<template>
  <b-button-group class="ml-auto">
    <b-input
      v-model="searchValue"
      size="sm"
      :placeholder="$t('general.search')"
      @update="sendUpdate"
    />
    <b-button
      v-if="searchValue == ''"
      variant="primary"
      v-b-tooltip.hover
      size="sm"
      :title="$t('general.search')"
    >
      <b-icon-search />
    </b-button>
    <b-button
      v-else
      variant="primary"
      v-b-tooltip.hover
      size="sm"
      :title="$t('general.search')"
      @click="clear()"
    >
      <b-icon-x />
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  data() {
    return {
      searchValue: "",
    };
  },
  methods: {
    sendUpdate(value) {
      this.$emit("updated", value);
    },
    clear() {
      this.searchValue = "";
      this.$emit("updated", "");
    },
  },
};
</script>

<style></style>
