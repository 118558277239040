<template>
  <div class="text-center">
    <b-icon-check-circle-fill v-if="booleanValue" variant="success" />
    <b-icon-x-circle-fill v-else variant="danger" />
  </div>
</template>

<script>
export default {
  props: {
    booleanValue: {
      type: Boolean,
      require: true,
    },
  },
};
</script>
