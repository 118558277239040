<template>
  <b-button
    variant="primary"
    v-b-tooltip.hover
    size="sm"
    :title="$t('views.customer.ActivateCustomer')"
    :disabled="!idCustomer"
    @click="activateCustomer"
  >
    <b-icon-check />
  </b-button>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    idCustomer: {
      type: Number,
      require: true,
    },
  },
  methods: {
    ...mapActions("Customer", ["ActiveCustomer"]),
    activateCustomer() {
      const that = this;
      this.ActiveCustomer({
        api: this.$api,
        idCustomer: this.idCustomer,
      })
        .then(() => {
          that.$emit("activated");
        })
        .catch(() => {
          that.busy = false;
        });
    },
  },
};
</script>
