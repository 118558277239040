<template>
  <b-button
    variant="primary"
    v-b-tooltip.hover
    size="sm"
    :title="$t('customer.buttons.removeCustomer')"
    :disabled="!idCustomer"
    @click="askConfirmation"
  >
    <b-icon-trash />
    <confirm
      id="delete-customer"
      :title="$t('customer.messages.confirmationDelete')"
      :message="$t('customer.messages.customerDisabled')"
      @onOk="removeCustomer"
    />
  </b-button>
</template>

<script>
import { mapActions } from "vuex";
import Confirm from "../Confirm.vue";

export default {
  components: {
    Confirm,
  },
  props: {
    idCustomer: {
      type: Number,
      require: true,
    },
  },
  methods: {
    ...mapActions("Customer", ["DeleteCustomer"]),
    askConfirmation() {
      this.$bvModal.show("delete-customer");
    },
    removeCustomer() {
      const that = this;
      this.DeleteCustomer({
        api: this.$api,
        idCustomer: this.idCustomer,
      })
        .then(() => {
          that.$emit("removed");
          that.$bvModal.hide("delete-customer");
        })
        .catch(() => {
          that.busy = false;
        });
    },
  },
};
</script>
