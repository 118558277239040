<template>
  <b-btn
    variant="primary"
    v-b-tooltip.hover
    size="sm"
    :title="$t('customer.buttons.editCustomer')"
    :disabled="!idCustomer"
    v-b-modal="id"
    id="update-customer-button"
  >
    <b-icon icon="pencil" />

    <b-modal
      :id="id"
      ref="update-customer"
      :title="$t('customer.buttons.editCustomer')"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      :hide-header-close="loading || busy"
      @hidden="resetModal"
      @show="onShow"
    >
      <b-overlay :show="loading">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="$t('customer.fields.name') + ': '"
            label-for="name-input"
            invalid-feedback="Name is required"
            label-cols="12"
            content-cols="12"
            :state="state.nameCustomer"
          >
            <b-form-input
              id="name-input"
              :placeholder="$t('customer.placeholders.updateCustomerName')"
              v-model="form.nameCustomer"
              maxlength="100"
              :state="state.nameCustomer"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :label="$t('customer.fields.unlimited') + ': '"
            label-for="unlimited-input"
            :state="state.unlimited"
          >
            <b-form-checkbox
              id="unlimited-input"
              title="Unlimited users"
              v-model="form.unlimited"
              button
              :button-variant="form.unlimited ? 'success' : 'danger'"
              @change="form.maxUsers = null"
            >
              {{
                $t(
                  "customer.values." +
                    (form.unlimited ? "unlimited" : "limited")
                )
              }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            v-if="!form.unlimited"
            :label="$t('customer.fields.maxUsers') + ': '"
            label-for="maxUsers-input"
            :invalid-feedback="
              'Max users is required and must be less than ' + maxUserMaxValue
            "
            label-cols="12"
            content-cols="12"
            :state="state.maxUsers"
          >
            <b-form-input
              id="maxUsers-input"
              placeholder="Enter max number of users"
              type="number"
              v-model="form.maxUsers"
              :state="state.maxUsers"
              required
              min="0"
              step="1"
              lazy-formatter
              :formatter="onlyIntegers"
            ></b-form-input>
          </b-form-group>
          <div v-if="!form.unlimited && form.maxUsers < form.currentUsers">
            <b-alert show variant="warning">
              {{ $t("customer.messages.currentUsersMaxUsers") }}
            </b-alert>
          </div>
          <b-form-group
            :label="$t('customer.fields.currentUsers') + ': '"
            label-cols="12"
            content-cols="12"
          >
            {{ form.currentUsers }}
          </b-form-group>
        </form>
        <template #overlay>
          <simple-loader />
        </template>
      </b-overlay>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="$bvModal.hide(id)" :disabled="busy || loading">{{
            $t("general.cancel")
          }}</b-button
          ><b-button
            variant="primary"
            @click="handleOk"
            :disabled="busy || loading"
            >{{ $t("general.ok") }}</b-button
          ></slot
        >
      </template>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        nameCustomer: null,
        unlimited: false,
        maxUsers: null,
        currentUsers: null,
      },
      state: {
        nameCustomer: null,
        maxUsers: null,
      },
      id: "update-customer-modal",
      loading: false,
      busy: false,
      maxUserMaxValue: 32767,
    };
  },
  props: {
    idCustomer: {
      type: Number,
      require: true,
    },
  },
  computed: {
    ...mapGetters("Customer", ["customer"]),
  },
  methods: {
    ...mapActions("Customer", ["getCustomer", "putCustomer"]),
    checkFormValidity() {
      let valid = (this.state.nameCustomer = this.state.maxUsers = true);
      if (!this.form.nameCustomer) this.state.nameCustomer = valid = false;
      if (
        (!this.form.unlimited && !this.form.maxUsers) ||
        (!this.form.unlimited && this.form.maxUsers < this.form.currentUsers) ||
        (!this.form.unlimited && this.form.maxUsers >= this.maxUserMaxValue)
      )
        this.state.maxUsers = valid = false;
      return valid;
    },
    onlyIntegers(value) {
      return Math.trunc(value);
    },
    resetModal() {
      this.form = {
        nameCustomer: null,
        unlimited: false,
        maxUsers: null,
        currentUsers: null,
      };
      this.state = {
        nameCustomer: null,
        maxUsers: null,
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.busy = true;
      this.putCustomer({
        api: this.$api,
        idCustomer: this.idCustomer,
        unlimited: this.form.unlimited,
        nameCustomer: this.form.nameCustomer,
        maxUsers: this.form.maxUsers,
      })
        .then(() => {
          this.$bvModal.hide(this.id);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    onShow() {
      this.loading = true;
      this.getCustomer({
        api: this.$api,
        idCustomer: this.idCustomer,
      })
        .then((response) => {
          this.form.nameCustomer = response.data.nameCustomer;
          this.form.unlimited = response.data.unlimited;
          this.form.maxUsers = response.data.maxUsers;
          this.form.currentUsers = response.data.currentUsers;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
