<template>
  <div>
    <alert />
    <b-card no-body class="h-100">
      <b-card-header>
        <b-row class="mb-3">
          <b-col>
            <h3>{{ $t("customer.title") }}</h3>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-form-checkbox v-model="enabled" name="check-button" switch>{{
              $t("customer.buttons.showEnabledCustomers")
            }}</b-form-checkbox>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <search-bar @updated="updateKeyword" />
              <b-button-group class="mx-1">
                <add-customer-option v-if="enabled" />
                <update-customer-option
                  :idCustomer="
                    currentCustomer ? currentCustomer.idCustomer : null
                  "
                  v-if="enabled"
                />
                <remove-customer-option
                  :idCustomer="
                    currentCustomer ? currentCustomer.idCustomer : null
                  "
                  v-if="enabled"
                  @removed="customerRemoved"
                />
                <activate-customer-option
                  :idCustomer="
                    currentCustomer ? currentCustomer.idCustomer : null
                  "
                  v-if="!enabled"
                  @activated="customerActivated"
                />
                <remove-customer-permanently-option
                  :idCustomer="
                    currentCustomer ? currentCustomer.idCustomer : null
                  "
                  v-if="!enabled"
                  @removed="customerRemovedPermanently"
                />
                <EditFeatures v-if="enabled" :selectedItem="currentCustomer" />
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="overflow-auto p-0">
        <b-table
          ref="Customers"
          :busy="busy"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          small
          thead-class="text-center"
          hover
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
        >
          <template v-slot:table-busy>
            <simple-loader />
          </template>

          <template v-slot:cell(nameCustomer)="data">
            {{ data.value }}
            <font-awesome-icon
              id="disabled-wrapper"
              icon="crown"
              class="text-warning"
              v-if="data.item.default"
            />
          </template>

          <template v-slot:cell(unlimited)="data">
            <boolean-icon :booleanValue="data.value" />
          </template>

          <template v-slot:cell(maxUsers)="data">
            <div class="text-center" v-if="!data.value">-</div>
            <div class="text-center" v-else>
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(currentUsers)="data">
            <div class="text-center">
              {{ data.value }}
            </div>
          </template>

          <template v-slot:cell(enabled)="data">
            <boolean-icon :booleanValue="data.value" />
          </template>
        </b-table>
      </b-card-body>
      <b-pagination
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="perPage"
        size="sm"
        align="center"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import alert from "@/components/general/alert";
import UpdateCustomerOption from "../components/Customers/UpdateCustomerOption.vue";
import AddCustomerOption from "../components/Customers/AddCustomerOption.vue";
import searchBar from "../components/general/search-bar.vue";
import BooleanIcon from "../components/general/icons/boolean-icon.vue";
import RemoveCustomerOption from "../components/Customers/RemoveCustomerOption.vue";
import ActivateCustomerOption from "../components/Customers/ActivateCustomerOption.vue";
import RemoveCustomerPermanentlyOption from "../components/Customers/RemoveCustomerPermanentlyOption.vue";
import EditFeatures from "@/components/Customers/EditFeatures";

export default {
  components: {
    alert,
    UpdateCustomerOption,
    AddCustomerOption,
    searchBar,
    BooleanIcon,
    RemoveCustomerOption,
    ActivateCustomerOption,
    RemoveCustomerPermanentlyOption,
    EditFeatures,
  },
  watch: {
    enabled() {
      this.fetchData();
    },
  },
  data() {
    return {
      enabled: true,
      perPage: 10,
      currentPage: 1,
      busy: false,
      selected: [],
      currentCustomer: null,
      keyword: "",
    };
  },
  methods: {
    ...mapActions("Customer", ["getCustomers"]),
    toast(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    onRowSelected(items) {
      this.currentCustomer = items[0];
    },
    customerActivated() {
      this.busy = false;
      this.toast(
        this.$t("views.customer.CustomerActivated"),
        this.$t("views.customer.CustomerActivatedSuccessfuly")
      );
    },
    customerRemoved() {
      this.busy = false;
      this.$refs.Customers.refresh();
      this.toast(
        this.$t("views.customer.CustomerRemoved"),
        this.$t("views.customer.CustomerRemovedSuccessfuly")
      );
    },
    customerRemovedPermanently() {
      this.busy = false;
      this.$refs.Customers.refresh();
      this.toast(
        this.$t("views.customer.CustomerRemoved"),
        this.$t("views.customer.CustomerRemovedSuccessfuly")
      );
    },
    updateKeyword(newKeyword) {
      this.keyword = newKeyword;
    },
    fetchData() {
      const that = this;
      this.busy = true;
      this.getCustomers({
        api: this.$api,
        enabled: this.enabled,
      })
        .then(() => {
          that.busy = false;
        })
        .catch(() => {
          that.busy = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters("Customer", ["customers"]),
    fields() {
      return [
        {
          key: "idCustomer",
          sortable: true,
          label: this.$t("customer.fields.id"),
        },
        {
          key: "nameCustomer",
          sortable: true,
          label: this.$t("customer.fields.name"),
        },
        {
          key: "unlimited",
          sortable: true,
          label: this.$t("customer.fields.unlimited"),
        },
        {
          key: "maxUsers",
          sortable: true,
          label: this.$t("customer.fields.maxUsers"),
        },
        {
          key: "currentUsers",
          sortable: true,
          label: this.$t("customer.fields.currentUsers"),
        },
        {
          key: "enabled",
          sortable: false,
          label: this.$t("customer.fields.enabled"),
        },
      ];
    },
    items() {
      return this.customers.filter((word) =>
        word.nameCustomer.toLowerCase().includes(this.keyword.toLowerCase())
      );
    },
  },
};
</script>
