<template>
  <b-button
    variant="danger"
    v-b-tooltip.hover
    size="sm"
    :title="$t('views.customer.RemoveCustomer')"
    :disabled="!idCustomer"
    @click="$bvModal.show(modalId)"
  >
    <b-icon-x-octagon />{{ $t("views.customer.RemoveCustomerPermanently") }}
    <confirm
      :id="modalId"
      :title="$t('views.customer.ModalRemove.title')"
      :message="$t('views.customer.ModalRemove.subtitle')"
      @onOk="removeCustomer"
    />
  </b-button>
</template>

<script>
import { mapActions } from "vuex";
import Confirm from "../Confirm.vue";

export default {
  components: {
    Confirm,
  },
  props: {
    idCustomer: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      modalId: "delete-customer-permanently-modal",
    };
  },
  methods: {
    ...mapActions("Customer", ["DeleteCustomerPermanently"]),
    askConfirmation() {
      this.$bvModal.show(this.modalId);
    },
    removeCustomer() {
      const that = this;
      this.DeleteCustomerPermanently({
        api: this.$api,
        idCustomer: this.idCustomer,
      })
        .then(() => {
          that.$emit("removed");
          that.$bvModal.hide(this.modalId);
        })
        .catch(() => {
          that.busy = false;
        });
    },
  },
};
</script>
