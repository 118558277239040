<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-tooltip.hover
    :title="$t('views.customer.features')"
    :disabled="!selectedItem"
    @click="onClick"
  >
    <b-icon-list-check />
    <ModalFeatures
      :selectedItem="selectedItem"
      :showModal="showModal"
      @onClose="onModalClose"
    />
  </b-btn>
</template>

<script>
import ModalFeatures from "@/components/Customers/ModalFeatures";

export default {
  props: {
    selectedItem: {
      type: Object,
      require: true,
    },
  },
  components: {
    ModalFeatures,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    onClick() {
      this.showModal = true;
    },
    onModalClose() {
      this.showModal = false;
    },
  },
};
</script>
