<template>
  <b-modal
    :visible="modalShow"
    :id="idModal"
    :title="
      selectedItem
        ? selectedItem.nameCustomer +
          ' ' +
          $t('views.customer.features').toLowerCase()
        : ''
    "
    centered
    header-bg-variant="primary"
    header-text-variant="light"
    :busy="loading"
    hide-footer
    @show="onShow"
    @hide="onClose"
  >
    <b-row v-if="getLoading">
      <b-col class="text-center">
        <simple-loader />
      </b-col>
    </b-row>
    <b-list-group v-else>
      <b-list-group-item
        v-for="item in customersFeatures"
        v-bind:key="item.idFeature"
      >
        <b-row>
          <b-col>
            {{ item.featureDescription }}
          </b-col>
          <b-col class="text-right">
            <b-button
              :pressed="item.enabled"
              :variant="item.enabled ? 'success' : 'danger'"
              size="sm"
              @click="onToggleClick(item)"
            >
              <b-icon :icon="item.enabled ? 'check-square' : 'x-square'" />
            </b-button>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    selectedItem: {
      type: Object,
      require: true,
    },
    showModal: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      idModal: "edit-features-modal",
      getLoading: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("Customer", ["getCustomerFeatures", "putCustomerFeature"]),
    onShow() {
      this.loading = true;
      this.getLoading = true;
      this.getCustomerFeatures({
        idCustomer: this.selectedItem.idCustomer,
      }).finally(() => {
        this.loading = false;
        this.getLoading = false;
      });
    },
    onClose() {
      this.$emit("onClose");
    },
    onToggleClick(item) {
      this.loading = true;
      this.putCustomerFeature({
        idCustomer: this.selectedItem.idCustomer,
        idFeature: item.idFeature,
        enabled: !item.enabled,
      })
        .catch((errorMsg) => {
          this.$bvToast.toast(errorMsg, {
            variant: "danger",
            title: this.$t("views.customer.features"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters("Customer", ["customersFeatures"]),
    modalShow() {
      return this.showModal;
    },
  },
};
</script>
